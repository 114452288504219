<template>
  <section class="invoice-add-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-overlay
          :show="tableOverlay"
          rounded="sm"
        >
          <b-form @submit.prevent>
            <b-card
              class="invoice-preview-card"
              title="Nueva ruta"
            >

              <!-- Invoice Client Details -->
              <b-card-body
                class="invoice-padding pt-0"
              >
                <div class="border rounded">
                  <b-row class="invoice-spacing">
                    <b-col md="12">
                        <b-form-group
                        label="Nombre de la ruta"
                        label-for="i-name"
                        >
                        <b-form-input
                            id="i-name"
                            v-model="routeData.name"
                        />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                        label-for="i-route"
                        label="País de origen"
                        >
                        <v-select
                          :options="countries"
                          label="name"
                          :clearable="false"
                          placeholder="Selecciona el país de origen"
                          :reduce="name => name.id"
                          v-on:input="detectUnsavedChange"
                          v-model="routeData.originCountry"
                          @input="getStateByCountry($event, 'origin')"
                        />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                        label-for="i-route"
                        label="Estado de origen"
                        >
                        <v-select
                          :options="originStates"
                          label="name"
                          :clearable="false"
                          placeholder="Selecciona el estado de origen"
                          :reduce="name => name.id"
                          v-on:input="detectUnsavedChange"
                          v-model="routeData.originState"
                        >
                          <span slot="no-options">Por favor, selecciona un país de origen primero.</span>
                        </v-select>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                        label-for="i-route"
                        label="País de destino"
                        >
                        <v-select
                          :options="countries"
                          label="name"
                          :clearable="false"
                          placeholder="Selecciona el país de destino"
                          :reduce="name => name.id"
                          v-on:input="detectUnsavedChange"
                          v-model="routeData.destinyCountry"
                          @input="getStateByCountry($event, 'destiny')"
                        />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                        label-for="i-route"
                        label="Estado de destino"
                        >
                        <v-select
                          :options="destinyStates"
                          label="name"
                          :clearable="false"
                          placeholder="Selecciona el estado de destino"
                          :reduce="name => name.id"
                          v-on:input="detectUnsavedChange"
                          v-model="routeData.destinyState"
                        >
                          <span slot="no-options">Por favor, selecciona un país de destino primero.</span>
                        </v-select>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                        label="Precio base"
                        label-for="i-stock"
                        >
                        <b-form-input
                            id="i-stock"
                            @keypress="restrictNumber($event)"
                            v-model="routeData.basePrice"
                        />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                        label-for="i-route"
                        label="Tipo de cambio"
                        >
                        <v-select
                          :options="currencies"
                          label="name"
                          :clearable="false"
                          placeholder="Selecciona el tipo de cambio"
                          :reduce="name => name.id"
                          v-on:input="detectUnsavedChange"
                          v-model="routeData.idCurrency"
                        />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                        label="Peso de la carga"
                        label-for="i-origin"
                        >
                        <b-form-input
                            id="i-origin"
                            @keypress="restrictNumber($event)"
                            v-model="routeData.weight"
                        />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                        label-for="i-route"
                        label="Unidad de medida"
                        >
                        <v-select
                          :options="measurementUnits"
                          label="name"
                          :clearable="false"
                          placeholder="Selecciona la unidad de medida"
                          :reduce="name => name.id"
                          v-on:input="detectUnsavedChange"
                          v-model="routeData.idMeasurementUnit"
                        />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                        label-for="i-route"
                        label="Bolsa"
                        >
                        <v-select
                          :options="stockNames"
                          label="name"
                          :clearable="false"
                          placeholder="Selecciona la bolsa"
                          :reduce="name => name.id"
                          v-on:input="detectUnsavedChange"
                          v-model="routeData.idStockName"
                        />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                        label-for="i-route"
                        label="Indicador de la bolsa"
                        >
                        <v-select
                          :options="stockIndicator"
                          label="name"
                          :clearable="false"
                          placeholder="Selecciona el indicador de la bolsa"
                          :reduce="name => name.id"
                          v-on:input="detectUnsavedChange"
                          v-model="routeData.stockIndicator"
                        />
                        </b-form-group>
                    </b-col>
                  </b-row>
                  <!-- Linea -->
                  <b-col cols="12">
                    <hr style="height:1px;border-width:0;color:gray;background-color:gray">
                  </b-col>
                  <b-row class="invoice-spacing">
                    <b-col>
                      <div>
                        <b-form
                          ref="form"
                          class="repeater-form"
                        >
                          <!-- Row Loop -->
                          <b-row
                            v-for="(commission, indexCommission) in routeData.commissions"
                            :id="commission.idExpense"
                            :key="indexCommission"
                            ref="row"
                          >
                            <!-- Gasto -->
                            <b-col md="4">
                              <b-form-group
                                label="Gasto"
                                label-for="item-name"
                              >
                                <v-select
                                  :options="expenses"
                                  label="name"
                                  input-id="invoice-data-client"
                                  :clearable="false"
                                  placeholder="Selecciona un gasto"
                                  :reduce="name => name.id"
                                  v-on:input="detectUnsavedChange"
                                  v-model='routeData.commissions[indexCommission].idExpense'
                                />
                              </b-form-group>
                            </b-col>
                            <!-- Precio -->
                            <b-col md="2">
                              <b-form-group
                                label="Cantidad estimada"
                                label-for="cost"
                              >
                                <b-form-input
                                  @keypress="restrictNumber($event)"
                                  v-model="routeData.commissions[indexCommission].price"
                                  type="number"
                                />
                              </b-form-group>
                            </b-col>
                            <!-- Tipo de cambio -->
                            <b-col md="4">
                                <b-form-group
                                label-for="i-route"
                                label="Tipo de cambio"
                                >
                                <v-select
                                  :options="currencies"
                                  label="name"
                                  :clearable="false"
                                  placeholder="Selecciona el tipo de cambio"
                                  :reduce="name => name.id"
                                  v-on:input="detectUnsavedChange"
                                  v-model="routeData.commissions[indexCommission].idCurrency"
                                />
                                </b-form-group>
                            </b-col>
                            <!-- Eliminar -->
                            <b-col md="2">
                              <b-button
                                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                                variant="outline-danger"
                                class="mt-0 mt-md-2"
                                @click="removeItem(indexCommission, 'commission')"
                              >
                                <feather-icon
                                  icon="XIcon"
                                  class="mr-25"
                                />
                                <span>Eliminar</span>
                              </b-button>
                            </b-col>

                            <b-col cols="12">
                              <br>
                            </b-col>
                          </b-row>

                        </b-form>
                      </div>
                      <div style="text-align: center;">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="outline-primary"
                          @click="addNewItem('commission')"
                        >
                        <feather-icon
                          icon="PlusIcon"
                          class="mr-25"
                        />
                        <span>Agregar gasto</span>
                      </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </div>
                <div v-if="routeData.commissions.length != 0" style="text-align: center; margin-top: 1em;">
                  <b-row>
                    <b-col cols="12" md="6">
                      <b-form-group>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="outline-primary"
                          v-b-tooltip.hover-focus.bottom.v-primary.right
                          :title="logisticPrice"
                          :disabled="!priceIsValid()"
                          @click="getLogisticPrice()"
                        >
                          <feather-icon
                            icon="MousePointerIcon"
                            class="mr-25"
                          />
                          <span> Consultar precio </span>
                        </b-button>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          @click="saveData()"
                        >
                          <feather-icon
                            icon="SaveIcon"
                            class="mr-25"
                          />
                          <span> Crear ruta nueva </span>
                        </b-button>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-card-body>
            </b-card>
          </b-form>
        </b-overlay>
      </b-col>
    </b-row>
    <!-- MODAL ALERTA -->
    <b-modal
      id="alertModal"
      title="Alerta"
      ok-title="Voy a revisarlo"
      ok-only
      centered
      no-close-on-backdrop
      hide-header-close
    >
      <b-alert
        variant="danger"
        show
      >
        <h4 class="alert-heading">
          Faltan datos
        </h4>
        <div class="alert-body font-medium-2">
          <p>Favor de llenar todos los campos.</p>
        </div>
      </b-alert>
    </b-modal>
  </section>
</template>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}

br {
  user-select: none;
}
</style>


<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import utils from '../../../../utils/utils.js';
import { Spanish } from "flatpickr/dist/l10n/es.js"
flatpickr.localize(Spanish);
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'

// temp
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

import { BTabs, BTab, BButton, BForm, BFormGroup, BSpinner,
BFormInput, BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText, 
BCardBody, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
BFormCheckbox, VBTooltip, BAlert, BFormTextarea, BPopover, VBToggle, BOverlay } from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'



export default {
  components: {

    BAlert,
    BTabs,
    BTab,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BCardBody,
    BFormTextarea,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
    VueApexCharts,
    BSpinner,
    BOverlay,

  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [heightTransition],
  data() {
    return {
      // Catalogs
      items: [],
      currencies: [],
      measurementUnits: [],
      countries: [],
      originStates: [],
      destinyStates: [],
      expenses: [],
      routes: [],
      stockNames: [],
      stockIndicator: [
        {
          id: 0,
          name: "Actual",
        },
        {
          id: 7,
          name: "7 dias",
        },
        {
          id: 30,
          name: "30 dias",
        }
      ],
      routeData: { 
        idRoute: null,
        basePrice: null, 
        weight: null,
        idStockName: null,
        stockIndicator: null,
        idCurrency: null,
        idMeasurementUnit: null,
        originState: null,
        originCountry: null,
        destinyState: null, 
        destinyCountry: null,
        commissions: [
          {
            idExpense: null,
            price: null,
            idCurrency: null,
          }
        ]
      },
      // Commissions
      commissionTemplate: {
        idExpense: null,
        price: null,
        idCurrency: null,
      },

      //Props
      tableOverlay: false,
      idRoute: null,
      logisticPrice: "Clic para calcular",
      byPassRouteLeave: true,
      // 
      configTime: {
        dateFormat: 'd-m-Y',
        locale: Spanish, // locale for this instance only          
      },
    }
  },
  created() {
    this.getUnits();
    this.getCountries();
    this.getMetals();
    this.getCommissions();
    // this.getRoutes();
    this.getStockNames();
  },
  beforeRouteLeave(to, from, next) { // Evitar que se salgan de la pantalla y perder datos
    if (!(this.byPassRouteLeave)) {
      const answer = window.confirm('¿Seguro que desea salir? Los cambios no guardados se perderán.');
      if (answer) {
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  },
  methods: {
    getUnits() {
      this.$axios.get(this.$services + 'general/get_units').then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          // console.log(res.data.data)
          this.currencies = res.data.data.currencies;
          this.measurementUnits = res.data.data.measurementUnits;
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      });
    },
    getCountries() {
      this.$axios.get(this.$services + 'general/get_countries').then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          // console.log(res.data.data)
          this.countries = res.data.data;
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      });
    },
    getStateByCountry(idCountry, type) {
      this.$axios.get(this.$services + 'general/get_states_country', {
        params: {
          idCountry: idCountry
        }
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          if (type == 'origin') {
            this.originStates = res.data.data;
          } else if (type == 'destiny') {
            this.destinyStates = res.data.data;
          }
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      });
    },
    getMetals() {
      this.$axios.get(this.$services + 'general/get_metals').then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          // console.log(res.data.data)
          this.items = res.data.data;
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      });
    },
    getCommissions() {
      this.$axios.get(this.$services + 'logistics/get_expenses').then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          // console.log(res.data.data)
          this.expenses = res.data.data;
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      });
    },
    getRoutes() {
      this.$axios.get(this.$services + 'logistics/get_routes').then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          // console.log(res.data.data)
          this.routes = res.data.data;
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      });
    },
    getStockNames() {
      this.$axios.get(this.$services + 'prices/get_stocknames').then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          // console.log(res.data.data)
          this.stockNames = res.data.data;
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      });
    },
    priceIsValid() {
      if (
        this.routeData.basePrice == null ||
        this.routeData.basePrice === '' ||
        this.routeData.idCurrency == null ||
        this.routeData.weight == null ||
        this.routeData.weight === '' ||
        this.routeData.idMeasurementUnit == null ||
        this.routeData.idStockName == null ||
        this.routeData.stockIndicator == null
      )
        return false;

      for (const commission of this.routeData.commissions)
        if (
          commission.idExpense == null ||
          commission.price == null ||
          commission.price === '' ||
          commission.idCurrency == null
        )
          return false;

      return true;
    },
    getLogisticPrice() {
      this.logisticPrice = 'Cargando...';
      this.routeData.basePrice = parseInt(this.routeData.basePrice);
      this.routeData.weight = parseInt(this.routeData.weight);
      this.$axios.post(this.$services + 'logistics/get_logistic_price', {
        routeData: this.routeData,
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.logisticPrice = res.data.data.logisticPrice + ' ' + res.data.data.currency + ' / ' + res.data.data.measurementUnit;
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
      });
    },
    createRoute() {
      this.tableOverlay = true;
      this.$axios.post(this.$services + 'logistics/create_route_commission', {
        commissionData: JSON.parse(JSON.stringify(this.routeData)),
      }).then(res => {
        this.$refreshToken(res.headers['x-access-token']);
        if (!res.data.error) {
          this.byPassRouteLeave = true;
          this.showToast('success', '👋  ¡Ruta guardada con éxito!');
          this.$router.push({ name: 'logisticRoute' });
        }
      }).catch(error => {
        this.$verifyToken(error.response.data.data);
        this.showToast('danger', String(error));
      }).finally(() => {
        this.tableOverlay = false;
      });
    },
    saveData() {
      this.checkMissingParameters() ? this.$bvModal.show('alertModal') : this.createRoute();
    },
    checkMissingParameters() {
      // Omitir  idRoute
      const sliced = Object.fromEntries(
          Object.entries(this.routeData).slice(1, 11)
      )
      // Checar primeros parámetros
      if (
        Object.values(sliced).includes(null) ||
        Object.values(sliced).includes('')
      )
        return true;
      // Checar comisiones
      for (const commission of this.routeData.commissions)
        if (
          Object.values(commission).includes(null) ||
          Object.values(commission).includes('')
        )
          return true;
      return false;  // No falta ningún parámetro
    },
    addNewItem(type) {
      if (type === 'commission') {
        this.routeData.commissions.push(JSON.parse(JSON.stringify(this.commissionTemplate)));
      }
    },
    removeItem(indexItem, type) {
      if (type === 'commission') {
        this.routeData.commissions.splice(indexItem, 1);
      }
    },
    detectUnsavedChange() {
      this.byPassRouteLeave = false;
      this.logisticPrice = 'Clic para calcular';
    },
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notificación',
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    restrictNumber(event){ // Solo numeros pueden ser ingresados
      let keyCode = (event.keyCode ? event.keyCode : event.which);
      let stringValue = event.target.value.toString();
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || stringValue.indexOf('.') != -1)) { // 46 is dot
        event.preventDefault();
        return;
      }
      this.detectUnsavedChange();
    },
    // TODO TERMINAR
    printInvoice() {
      window.print()
    },
  },
  computed: {
  },
}
</script>
